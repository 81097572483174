// NavigationMenu.js
import React from 'react';
import { Offcanvas, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const NavigationMenu = ({
  showMenu,
  handleMenuToggle,
  onGoToLocationClick,
  shajraEnabled,
  shajraVisible,
  toggleShajraVisible,
  clearCachedTiles,
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    handleMenuToggle();
  };

  // Decide label based on whether Shajra is currently visible
  const shajraLabel = shajraVisible ? 'Unload Shajra Parcha' : '📜 Load Shajra Parcha';

  const handleShajraToggle = () => {
    if (shajraEnabled) {
      if (shajraVisible) {
        console.log("Unloading Shajra and clearing cached tiles.");
        clearCachedTiles(); // Clear cached tiles when unloading Shajra
      }
      toggleShajraVisible(); // Toggle Shajra visibility
      handleMenuToggle(); // Close the menu after toggling
    }
  };
  

  return (
    <Offcanvas show={showMenu} onHide={handleMenuToggle} placement="start">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Navigation</Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body style={{ minWidth: '200px', maxWidth: '300px' }}>
        <Nav className="flex-column">
          <Nav.Link onClick={() => handleNavigation('/')}>🌍 Map</Nav.Link>

          {user?.userType === 'admin' && (
            <>
              <Nav.Link onClick={() => handleNavigation('/register')}>📝 Register User</Nav.Link>
              <Nav.Link onClick={() => handleNavigation('/users')}>👥 View Users</Nav.Link>
            </>
          )}

          {!user && <Nav.Link onClick={() => handleNavigation('/login')}>🔐 Login</Nav.Link>}

          <Nav.Link onClick={onGoToLocationClick}>📍 Go To Location</Nav.Link>

          {/* Toggleable Load/Unload Shajra Parcha Menu Item */}
          <Nav.Link
            disabled={!shajraEnabled}
            onClick={handleShajraToggle}
          >
            {shajraLabel}
          </Nav.Link>
        </Nav>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default NavigationMenu;
