import React, { useEffect, useState } from 'react';
import { Table, Alert, Button, ButtonGroup, Spinner, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from './AuthContext';
import './styles/global.css';
import './styles/table.css';
import './styles/modal.css';

import UserEditForm from './UserEditForm';
import { calculateEndDate, calculateDaysRemaining, calculateStatus } from './userUtils';
import { FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight } from 'react-icons/fa';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [editUserId, setEditUserId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [sortConfig, setSortConfig] = useState({});

  const { logout } = useAuth();

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    applyFiltersAndSort();
  }, [filters, sortConfig, users]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`);
      const updatedUsers = response.data.map((user) => ({
        ...user,
        daysRemaining: calculateDaysRemaining(user.startDate, user.subscriptionType),
        status: calculateStatus(calculateEndDate(user.startDate, user.subscriptionType)),
        endDate: calculateEndDate(user.startDate, user.subscriptionType),
      }));
      setUsers(updatedUsers);
      setFilteredUsers(updatedUsers);
      setError(null);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to load user data.');
    } finally {
      setLoading(false);
    }
  };

  const applyFiltersAndSort = () => {
    let result = [...users];
  
    // Apply Filters
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        result = result.filter((user) =>
          String(user[key]).toLowerCase().includes(filters[key].toLowerCase())
        );
      }
    });
  
    // Apply Sorting
    if (sortConfig.key) {
      result.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];
  
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sortConfig.direction === 'asc'
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }
        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
        }
        return 0;
      });
    }
  
    setFilteredUsers(result);
  };
  
  

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleFilterChange = (e, key) => {
    setFilters((prev) => ({ ...prev, [key]: e.target.value }));
  };

  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const handleEdit = (userId) => {
    setEditUserId(userId);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEditUserId(null);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/users/${userToDelete}`);
      setShowDeleteModal(false);
      setUserToDelete(null);
      fetchUsers();
    } catch (error) {
      console.error('Failed to delete user:', error);
      setError('Failed to delete user.');
    }
  };

  if (loading) return <Spinner animation="border" variant="primary" />;
  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <div className="container mt-1">
      <h2 className="text-center mb-3">User List</h2>

      <div className="responsive-table-container" style={{ overflowY: 'auto', maxHeight: '75vh' }}>
        <Table striped bordered hover responsive className="table-tech">
        <thead className="table-header">
  {/* First Row: Column Names */}
  <tr>
    {[
      { key: 'userName', label: 'User Name' },
      { key: 'userId', label: 'User\nID' },
      { key: 'mobileNumber', label: 'Mobile\nNumber' },
      { key: 'tehsil', label: 'Tehsil' },
      { key: 'mauzaList', label: 'Mauza\nList' },
      { key: 'subscriptionType', label: 'Subscription\nType' },
      { key: 'startDate', label: 'Start\nDate' },
      { key: 'endDate', label: 'End\nDate' },
      { key: 'daysRemaining', label: 'Days\nRemaining' },
      { key: 'status', label: 'Status' },
      { key: 'userType', label: 'User\nType' },
    ].map(({ key, label }) => (
      <th
        key={key}
        onClick={() => handleSort(key)}
        style={{
          cursor: 'pointer',
          textAlign: 'center',
          verticalAlign: 'middle',
          whiteSpace: 'pre-wrap',
        }}
      >
        {label}
        {sortConfig.key === key && (
          <span style={{ marginLeft: '5px', fontSize: '0.85rem' }}>
            {sortConfig.direction === 'asc' ? '▲' : '▼'}
          </span>
        )}
      </th>
    ))}
    <th>Actions</th>
  </tr>

  {/* Second Row: Filters */}
  <tr>
    {[
      'userName',
      'userId',
      'mobileNumber',
      'tehsil',
      'mauzaList',
      'subscriptionType',
      'startDate',
      'endDate',
      'daysRemaining',
      'status',
      'userType',
    ].map((key) => (
      <th key={`filter-${key}`} style={{ padding: '5px' }}>
        <Form.Control
          className="filter-input"
          type="text"
          placeholder={`Search`}
          value={filters[key] || ''}
          onChange={(e) => handleFilterChange(e, key)}
          style={{
            fontSize: '0.85rem',
            padding: '4px 6px',
          }}
        />
      </th>
    ))}
    <th></th>
  </tr>
</thead>


          <tbody>
            {currentUsers.map((user) => (
              <tr key={user.userId}>
                <td>{user.userName}</td>
                <td>{user.userId}</td>
                <td>{user.mobileNumber}</td>
                <td>{user.tehsil}</td>
                <td>
                  <Form.Control as="select" size="sm" defaultValue="">
                    {user.mauzaList.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                </td>
                <td>{user.subscriptionType}</td>
                <td>{new Date(user.startDate).toLocaleDateString()}</td>
                <td>{new Date(user.endDate).toLocaleDateString()}</td>
                <td>{user.daysRemaining}</td>
                <td>{user.status}</td>
                <td>{user.userType}</td>
                <td>
                  <ButtonGroup size="sm">
                    <Button variant="warning" onClick={() => handleEdit(user.userId)} title="Edit User">
                      <i className="fas fa-edit"></i>
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => {
                        setUserToDelete(user.userId);
                        setShowDeleteModal(true);
                      }}
                    >
                      Delete
                    </Button>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div className="pagination-controls d-flex justify-content-between align-items-center mt-3">
  {/* Records Information */}
  <div className="record-info">
    Showing {filteredUsers.length} of {users.length} records
  </div>

  {/* Pagination Buttons */}
  <div className="d-flex align-items-center">
    <Button
      variant="outline-primary"
      onClick={() => handlePageChange(1)}
      disabled={currentPage === 1}
    >
      <FaAngleDoubleLeft />
    </Button>
    <Button
      variant="outline-primary"
      onClick={() => handlePageChange(currentPage - 1)}
      disabled={currentPage === 1}
    >
      <FaAngleLeft />
    </Button>
    <span className="mx-2">
      Page {currentPage} of {totalPages}
    </span>
    <Button
      variant="outline-primary"
      onClick={() => handlePageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
    >
      <FaAngleRight />
    </Button>
    <Button
      variant="outline-primary"
      onClick={() => handlePageChange(totalPages)}
      disabled={currentPage === totalPages}
    >
      <FaAngleDoubleRight />
    </Button>
  </div>
</div>


      {editUserId && (
        <UserEditForm
          userId={editUserId}
          show={showEditModal}
          handleClose={handleCloseEditModal}
          refreshUsers={fetchUsers}
        />
      )}

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserList;
