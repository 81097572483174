import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  // Initialize user state from localStorage
  const [user, setUser] = useState(() => JSON.parse(localStorage.getItem('user')) || null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalError, setModalError] = useState({ show: false, title: '', message: '' });
  const navigate = useNavigate();

  const parseMauzaList = (mauzaList) => {
    if (typeof mauzaList === 'string') {
      return mauzaList.split(',').map((m) => m.trim());
    }
    return mauzaList || [];
  };

  const fetchUserProfile = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
        withCredentials: true,
      });
      const userData = response.data;
      userData.mauzaList = parseMauzaList(userData.mauzaList);

      // Store user data in localStorage to persist across browser tabs and sessions
      localStorage.setItem('user', JSON.stringify(userData));
      setUser(userData);
    } catch (error) {
      console.error('Failed to fetch user profile:', error);
      setUser(null);
      localStorage.removeItem('user'); // Remove user from localStorage on error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch user profile when the app loads if user data exists in localStorage
    if (localStorage.getItem('user')) {
      fetchUserProfile();
    } else {
      setLoading(false); // Avoid loading state if no user is found
    }
  }, []);

  const login = async (credentials) => {
    try {
      // Clear any previous auth token
      document.cookie = "authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      setLoading(true);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, credentials, {
        withCredentials: true,
      });

      // Fetch and store the user profile after successful login
      await fetchUserProfile();
      navigate('/');
    } catch (error) {
      setLoading(false);

      if (error.response?.status === 401) {
        console.error('Unauthorized: Invalid credentials.');
        setModalError({
          show: true,
          title: 'Invalid Credentials',
          message: 'User ID or Password is incorrect. Please try again.',
        });
      } else if (error.response?.status === 403 && error.response.data?.userDetails) {
        const userDetails = error.response.data.userDetails || {
          userName: 'Unknown',
          startDate: null,
          endDate: null,
          daysRemaining: 0,
        };
        setModalError({
          show: true,
          title: 'Subscription Expired',
          message: `Dear ${userDetails.userName}, your subscription has expired. Please contact 0304-8840264 to renew Subscription.`,
        });
      } else {
        console.error('Login failed:', error.response?.data || error.message);
        setError('Login failed. Please check your credentials.');
      }
      throw new Error('Login failed.');
    }
  };

  const logout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/logout`, {}, { withCredentials: true });
      document.cookie = "authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      setUser(null);
      localStorage.removeItem('user'); // Clear user data from localStorage on logout
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const changePassword = async (oldPassword, newPassword) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/change-password`,
        { userId: user.userId, oldPassword, newPassword },
        { withCredentials: true }
      );

      document.cookie = "authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      await logout();
      setError(null);
    } catch (error) {
      console.error('Password change failed:', error);
      setError('Failed to change password. Please try again.');
      throw new Error('Failed to change password.');
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        userType: user?.userType,
        login,
        logout,
        changePassword,
        loading,
        error,
        modalError,
        setModalError, // Expose modalError and setter for external handling
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
