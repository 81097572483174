import React, { useState, useEffect, Suspense, lazy, useCallback } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useAuth } from './AuthContext';
import Header from './App/Header';
import Modals from './App/Modals';
import NavigationMenu from './App/NavigationMenu';
import UserRegistration from './UserRegistration';
import UserList from './UserList';
import Login from './Login';
import GoToLocationForm from './Map Component/GoToLocationForm';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/global.css';
import './styles/navbar.css';
import './styles/dropdown.css';
import { calculateDaysRemaining } from './userUtils';

const MapComponent = lazy(() => import('./Map Component/MapComponent'));

const App = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showGoToForm, setShowGoToForm] = useState(false);
  const [mapCenter, setMapCenter] = useState([30, 71]);
  const [goToMarkers, setGoToMarkers] = useState([]);
  const [warningContent, setWarningContent] = useState({});
  const [selectedMauza, setSelectedMauza] = useState('');
  const [geoJsonPath, setGeoJsonPath] = useState(null);
  const [murabbaOptions, setMurabbaOptions] = useState([]); // Murabba_No options
  const [selectedMurabba, setSelectedMurabba] = useState(null); // Selected Murabba

  // Tracks if Shajra can be loaded (exists)
  const [shajraEnabled, setShajraEnabled] = useState(false);

  // Tracks if Shajra is currently displayed
  const [shajraVisible, setShajraVisible] = useState(false);

  const { user, loading, modalError, setModalError } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Function to clear cached tiles
  const clearCachedTiles = async () => {
    if ('indexedDB' in window) {
      const dbRequest = window.indexedDB.deleteDatabase("rasterDB");
      dbRequest.onsuccess = () => {
        console.log("IndexedDB cache cleared successfully.");
      };
      dbRequest.onerror = (event) => {
        console.error("Error clearing IndexedDB cache:", event.target.error);
      };
    }
  };

  // Dynamically set viewport height
  useEffect(() => {
    const setViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setViewportHeight();
    window.addEventListener('resize', setViewportHeight);

    return () => window.removeEventListener('resize', setViewportHeight);
  }, []);

  // Handle user subscription checks
  useEffect(() => {
    if (!user) {
      setSelectedMauza('');
      setGeoJsonPath(null);
      setMurabbaOptions([]);
    } else {
      const daysRemaining = calculateDaysRemaining(user.startDate, user.subscriptionType);
      if (daysRemaining <= 0) {
        setWarningContent({
          title: 'Subscription Expired',
          userName: user.userName,
          startDate: new Date(user.startDate).toLocaleDateString(),
          endDate: new Date(user.endDate).toLocaleDateString(),
          daysRemaining: 0,
          message: 'Your subscription has expired. Please contact support to renew!',
        });
        setShowWarningModal(true);
      } else if (daysRemaining <= 5) {
        setWarningContent({
          title: 'Subscription Expiring Soon',
          userName: user.userName,
          startDate: new Date(user.startDate).toLocaleDateString(),
          endDate: new Date(user.endDate).toLocaleDateString(),
          daysRemaining,
          message: `Your subscription will expire in ${daysRemaining} days. Please renew soon to continue enjoying services.`,
        });
        setShowWarningModal(true);
      }
    }
  }, [user]);

  // Reset "Go To Location" form visibility on route change
  useEffect(() => {
    if (location.pathname !== '/') {
      setShowGoToForm(false);
    }
  }, [location]);

  const handleMenuToggle = () => setShowMenu((prev) => !prev);
  const handleProfileClick = () => (user ? setShowProfileModal(true) : navigate('/login'));

  const handleMauzaChange = useCallback(
    (mauza) => {
      if (user) {
        const path = `/JSON Murabba/${user.tehsil}/${mauza}.geojson`;
        setGeoJsonPath(path);
        setSelectedMauza(mauza);
        setMurabbaOptions([]); // Clear dropdown when changing Mauza
        setSelectedMurabba(null); // Clear selected Murabba
        setShajraEnabled(false); // Reset "Load Shajra" availability
        setShajraVisible(false); // Also reset the visibility (unload if previously loaded)
        clearCachedTiles(); // Clear cache when changing Mauza
      }
    },
    [user]
  );

  const handleSetMurabbaOptions = useCallback((options) => {
    setMurabbaOptions((prev) => {
      if (JSON.stringify(prev) !== JSON.stringify(options)) {
        return [...options];
      }
      return prev;
    });
  }, []);

  const handleGoToLocation = ({ lat, lng }) => {
    setMapCenter([lat, lng]);
    setGoToMarkers((prev) => [...prev, [lat, lng]]);
    setShowGoToForm(false);
  };

  const handleMurabbaSelection = (murabba) => {
    setSelectedMurabba(murabba);
  };

  return (
    <div className="app-container" style={{ height: 'calc(var(--vh, 1vh) * 100)' }}>
      <Header
        handleMenuToggle={handleMenuToggle}
        handleProfileClick={handleProfileClick}
        handleMauzaChange={handleMauzaChange}
        selectedMauza={selectedMauza}
        setSelectedMauza={setSelectedMauza}
        murabbaOptions={murabbaOptions}
        handleMurabbaSelection={handleMurabbaSelection}
        setShajraEnabled={setShajraEnabled} // Pass state setter for "Load Shajra Parcha"
      />

      <NavigationMenu
        showMenu={showMenu}
        handleMenuToggle={handleMenuToggle}
        onGoToLocationClick={() => {
          if (location.pathname === '/') {
            setShowGoToForm(true);
            setShowMenu(false);
          }
        }}
        // Pass both "enabled" and "visible" states for Shajra
        shajraEnabled={shajraEnabled}
        shajraVisible={shajraVisible}
        toggleShajraVisible={() => {
          if (shajraEnabled) {
            setShajraVisible((prev) => !prev);
            if (!shajraVisible) {
              console.log("Loading Shajra...");
            } else {
              console.log("Unloading Shajra and clearing cache.");
              clearCachedTiles(); // Clear tiles when toggling off
            }
          }
        }}
        clearCachedTiles={clearCachedTiles}
      />

      {loading ? (
        <Spinner animation="border" />
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route
              path="/"
              element={
                <MapComponent
                  geoJsonUrl={geoJsonPath}
                  mapCenter={mapCenter}
                  goToMarkers={goToMarkers}
                  onClearMarkers={() => setGoToMarkers([])}
                  setMurabbaOptions={handleSetMurabbaOptions}
                  selectedMurabba={selectedMurabba}
                  // Pass in these props for Shajra logic
                  shajraVisible={shajraVisible}
                  user={user}
                  selectedMauza={selectedMauza}
                />
              }
            />
            <Route path="/register" element={<UserRegistration />} />
            <Route path="/users" element={<UserList />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Suspense>
      )}

      {showGoToForm && location.pathname === '/' && (
        <GoToLocationForm
          onGoToLocation={handleGoToLocation}
          onClose={() => setShowGoToForm(false)}
          onClearMarkers={() => setGoToMarkers([])}
        />
      )}

      <Modals
        showWarningModal={showWarningModal}
        setShowWarningModal={setShowWarningModal}
        warningContent={warningContent}
        showProfileModal={showProfileModal}
        setShowProfileModal={setShowProfileModal}
        showChangePassword={showChangePassword}
        setShowChangePassword={setShowChangePassword}
        errorModal={modalError}
        setErrorModal={setModalError}
      />
    </div>
  );
};

export default App;
